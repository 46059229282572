import React, { useEffect, useState } from "react";
import DarkTheme from "layouts/Dark";
import Services8 from "components/Services8/services8";
import Services6 from "components/Services6/services6";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import { GetServicesData } from "components/ApiServices/GetServicesData";
import SContactForm from "components/s-contact-form/s-contact-form";
import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const MobileAppServicesDark = (props) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState("Dark");
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const [servicesData, setServiceData] = useState(GetServicesData());
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  useEffect(() => {
    // Get the selected service ID from local storage
    const serviceId = localStorage.getItem("selectedServiceId");

    // Set the selectedServiceId to the retrieved value (null if not found)
    setSelectedServiceId(serviceId);
  }, []);

  const handleServiceItemClick = (serviceId) => {
    setSelectedServiceId(serviceId);
    // console.log("Selected Service ID in MobileAppServicesDark:", serviceId);
    // Add this line to log the selected ID
  };

  // Filter the servicesData based on the selectedServiceId
  const filteredServicesData = selectedServiceId
    ? servicesData.filter((data) => data.node.slug === selectedServiceId)
    : servicesData;
  // console.log("filteredServicesData", filteredServicesData);

  // Find the service object in servicesData based on selectedServiceId
  const selectedService = servicesData.find(
    (data) => data.node.slug === selectedServiceId
  );

  // Get the data of the selected service
  const selectedServiceTitle = selectedService?.node?.title;
  const selectedServicedescription = selectedService?.node?.description;
  const selectedServiceimg = selectedService?.node?.serviceImage?.guid;

  React.useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }

    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);
  }, [fixedHeader, navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme mobileappstyle>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
        onServiceItemClick={handleServiceItemClick}
      />

      <header
        ref={fixedHeader}
        className="works-header fixed-slider hfixd valign sub-bg"
      >
        <ParticalsOverlay />
        <div className="container">
          <div className="service-head-container">
            <div className="service-head-img">
              <img loading ="lazy" src={selectedServiceimg} alt="" />
            </div>
            <div className="head-details">
              <h2>{selectedServiceTitle}</h2>

              <p>{selectedServicedescription}</p>
            </div>
          </div>
        </div>
      </header>
      <div ref={MainContent} className="main-content">
        <Services8 servicesData={filteredServicesData} props={props} />
        <SContactForm />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO 
      canonical={liveUrl} />
      <link
        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
    </>
  );
};

export default MobileAppServicesDark;
